export default [
  {
    path: "/mon-osmose",
    component: () => import("@/pages/myOsmose/IndexMyOsmose.vue"),
    beforeEnter: (to, from, next) => {
      const store = require("../store/index");
      const typeBu = store.default.state.currentBu.tenant.type;
      if (typeBu == "BU") {
        next();
      } else {
        next({ name: "myManagement" });
      }
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "myOsmose",
        component: () => import("@/pages/myOsmose/MyOsmose.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "recherche-patient/:keyFilter?",
        name: "searchPatient",
        component: () => import("@/pages/myOsmose/SearchPatients.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      // {
      //   path: "inventory/:inventoryUUID",
      //   name: "inventoryStock",
      //   component: () => import("@/pages/inventory/inventoryPages.vue"),
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },
      {
        path: "patient/:patientPath",
        // name: "indexPatient",
        component: () => import("../pages/myOsmose/patients/IndexPatient"),
        children: [
          {
            path: "",
            name: "detailsPatient",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import("../pages/myOsmose/patients/DetailsPatient"),
          },
          {
            path: "devis-auditif",
            name: "auditoryQuote",
            component: () =>
              import("@/pages/myOsmose/patients/PatientAuditoryQuote.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "devis-optique",
            name: "opticQuote",
            component: () =>
              import("@/pages/myOsmose/patients/PatientOpticQuote.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "devis-lentille",
            name: "contactLensQuote",
            component: () =>
              import("@/pages/myOsmose/patients/PatientContactLensQuote.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "devis-equipement",
            name: "equipmentQuote",
            component: () =>
              import("@/pages/myOsmose/patients/PatientEquipmentQuote.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "dossier-de-pret",
            name: "loanQuote",
            component: () =>
              import("@/pages/myOsmose/patients/PatientEquipmentQuote.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "speciality",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import("../pages/myOsmose/patients/PatientSpeciality"),
            children: [
              {
                path: "healthAuditory",
                name: "patientHealthAuditory",
                component: () =>
                  import(
                    "@/pages/myOsmose/patients/childsPatientSpeciality/PatientHealthAuditory.vue"
                  ),
                meta: {
                  requiresAuth: true,
                },
              },
            ],
          },

          {
            path: "history",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import("../pages/myOsmose/patients/PatientHistory"),
            children: [
              {
                path: "",
                name: "patientHistoryOverview",
                component: () =>
                  import(
                    "@/pages/myOsmose/patients/childsPatientHistory/PatientOverview.vue"
                  ),
                meta: {
                  requiresAuth: true,
                },
              },

              {
                path: "invoices",
                name: "patientHistoryInvoices",
                component: () =>
                  import(
                    "@/pages/myOsmose/patients/childsPatientHistory/PatientInvoices.vue"
                  ),
                meta: {
                  requiresAuth: true,
                },
              },
              {
                path: "quotes",
                name: "patientHistoryQuotes",
                component: () =>
                  import(
                    "@/pages/myOsmose/patients/childsPatientHistory/PatientQuotes.vue"
                  ),
                meta: {
                  requiresAuth: true,
                },
              },
              {
                path: "devices",
                name: "patientHistoryDevices",
                component: () =>
                  import(
                    "@/pages/myOsmose/patients/childsPatientHistory/PatientDevices.vue"
                  ),
                meta: {
                  requiresAuth: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: "vente-libre",
        name: "freeQuote",
        component: () => import("@/pages/myOsmose/FreeQuote.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "encaissement",
        name: "payment",
        component: () => import("@/pages/myOsmose/Payment.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];
