import store from "../store";

export const clientTitle = {
  getName() {
    const shopValue = store.getters.currentShopInfo;
    if (shopValue == null || shopValue.specialityOptic) {
      return "client";
    } else {
      return "patient";
    }
  },
};
